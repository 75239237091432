<template>
  <div id="ordinary">
    <div class="main" v-if="$route.path === '/tool/member'">
      <div class="header">
        <div>
          <el-button
            v-if="showRemoveNew"
            type="primary"
            size="medium"
            @click="newmember"
          >
            新建会员
          </el-button>
        </div>

        <div class="right">
          <el-select
            v-model="searchOption.is_show"
            style="width: 120px"
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-select
            v-model="searchOption.status"
            class="mr20 ml20"
            style="width: 120px"
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-input
            v-model="searchOption.search"
            placeholder="输入会员名称搜索"
            size="medium"
            style="width: 218px"
          ></el-input>
        </div>
      </div>
      <!-- <div class="header_tow">
        <el-switch
          v-model="value3"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="header_tow_font">会员模块已开启</div>
        <el-popover placement="top-start" width="420" trigger="hover">
          <div class="popover_main">
            <div class="font1">
              开启后，在微信H5网校首页会展示【会员】模块，有效吸引学员购买会员；关闭后，首页不展示，会员信息只在个人中心和课程详情展示
            </div>
            <img
              style="margin: 0 auto"
              src="../.././../../assets/img/1.4.0/img_syt.png"
              alt=""
            />
          </div>
          <i slot="reference" class="el-icon-question"></i>
        </el-popover>
      </div> -->
      <pagination2
        ref="child"
        :option="searchOption"
        url="/courseMember/courseMemberList"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="会员名称" prop="number" min-width="270">
              <template slot-scope="{ row }">
                <div class="name_contain">
                  <div class="tu1">
                    <img
                      :src="row.cover_img"
                      style="width: 100px; height: 56px"
                      alt=""
                    />
                  </div>
                  <div class="right">
                    <div class="rigth_font" :title="row.name">
                      {{ row.name }}
                    </div>
                    <div class="right_font2">
                      <img
                        v-if="row.is_show == 1"
                        src="../../../../assets/img/1.4.0/icon_dg备份 4.png"
                        alt=""
                      />
                      <img
                        v-else
                        alt=""
                        src="../../../../assets/img/1.4.0/icon_cw备份 7.png"
                      />
                      <span v-if="row.is_show == 1">展示到网校</span>
                      <span v-else>不展示到网校</span>
                      <img
                        v-if="row.status == 1"
                        class="ml30"
                        src="../../../../assets/img/1.4.0/icon_dg备份 4.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="ml30"
                        src="../../../../assets/img/1.4.0/icon_cw备份 7.png"
                        alt=""
                      />
                      <span v-if="row.status == 1">已启用</span>
                      <span v-else>已停用</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="描述" min-width="130px">
              <template slot-scope="{ row }">
                {{ row.describe }}
              </template>
            </el-table-column>
            <el-table-column label="会员数">
              <template slot-scope="{ row }">
                <el-button type="text" @click="memberexamine(row)">
                  {{ row.num }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </template>
            </el-table-column>

            <el-table-column label="课程数">
              <template slot-scope="{ row }">
                <el-button type="text" @click="editMember(row, 2)">
                  {{ row.course_count }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="创建人" prop="uname"></el-table-column>
            <el-table-column label="创建时间" prop="costprice" min-width="100">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
              <!-- formatTimeStamp('yyyy-MM-dd hh:mm') -->
            </el-table-column>
            <el-table-column label="操作" align="right" min-width="170px">
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button type="text" @click="memberexamine(row)">
                    查看会员
                  </el-button>
                  <el-divider
                    direction="vertical"
                    class="fl"
                    style="background: #1b9d97"
                  ></el-divider>
                  <el-button type="text" @click="share(row)">分享</el-button>
                  <el-divider
                    direction="vertical"
                    class="fl"
                    style="background: #1b9d97"
                  ></el-divider>
                  <div class="fl">
                    <el-dropdown>
                      <span class="el-dropdown-link cp">更多</span>
                      <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item>
                          <p @click="share(row)">分享</p>
                        </el-dropdown-item> -->
                        <el-dropdown-item v-if="showRemoveEdit">
                          <p @click="editMember(row, 1)">编辑</p>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="showRemoveDisableAble">
                          <p @click="stop(row, 1, 1)" v-if="row.status == 2">
                            启用会员
                          </p>
                          <p v-else @click="stop(row, 1, 2)">停用会员</p>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="row.is_show == 2">
                          <p @click="show(row, 2, 1)">展示到网校</p>
                        </el-dropdown-item>
                        <el-dropdown-item v-else>
                          <p @click="show(row, 2, 2)">不展示到网校</p>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="showRemoveDel">
                          <p @click="del(row, 3)">删除</p>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
      <downewm v-if="status" :val="val" :dialogstatus.sync="status"></downewm>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import downewm from '@cm/base/downewm'
export default {
  name: 'ordinary',
  components: {
    downewm,
  },
  data() {
    return {
      status: false,
      options: [
        {
          value: 0,
          label: '全部类型',
        },
        {
          value: 1,
          label: '展示到网校',
        },
        {
          value: 2,
          label: '不展示到网校',
        },
      ],
      options2: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '已启用',
        },
        {
          value: 2,
          label: '已停用',
        },
      ],
      searchOption: {
        is_show: 0, //是否网校展示 1 是 2 否
        status: 0, //课程会员状态 1 启用 2 停用
        search: '', //  搜索内容
      },
      url: '', //生成二维码的地址
      val: {}, //点击分享获取到数据
    }
  },
  computed: {
    // 新建
    showRemoveNew() {
      return this.$store.state.roots.includes(123)
    },
    // 编辑
    showRemoveEdit() {
      return this.$store.state.roots.includes(126)
    },
    // 启用/停用会员
    showRemoveDisableAble() {
      return this.$store.state.roots.includes(127)
    },
    // 删除
    showRemoveDel() {
      return this.$store.state.roots.includes(128)
    },
  },
  methods: {
    //分享
    share(val) {
      this.status = true
      this.val = val
      this.url = val.url
    },
    //停用启用
    stop(row, val2, val3) {
      if (val3 == 2) {
        this.$confirm(
          '停用后，已获得该会员权益的学员将全部失效；重新启用会员功能，在有效期内的学员自动恢复权益！',
          '停用会员',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.$http({
              url: '/courseMember/stopCourseMember',
              data: {
                type: val2,
                status: val3,
                member_id: row.member_id,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.$refs.child.reset()
              },
            })
          })
          .catch(() => {})
      } else {
        this.$http({
          url: '/courseMember/stopCourseMember',
          data: {
            type: val2,
            status: val3,
            member_id: row.member_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
        })
      }
    },

    //展示到网校，不展示到网校
    show(row, val2, val3) {
      if (val3 == 2) {
        this.$confirm(
          '隐藏后不会在网校显示会员信息，分享的二维码或链接进入无法看到会员内容，只可单独设置学员为会员角色，已成为会员角色会员内容可见！',
          '不展示到网校',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.$http({
              url: '/courseMember/stopCourseMember',
              data: {
                type: val2,
                is_show: val3,
                member_id: row.member_id,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.$refs.child.reset()
              },
            })
          })
          .catch(() => {})
      } else {
        this.$http({
          url: '/courseMember/stopCourseMember',
          data: {
            type: val2,
            is_show: val3,
            member_id: row.member_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
        })
      }
    },

    //删除数据
    del(row, val2) {
      this.$confirm(
        '删除后，已获得该会员权益的学员将全部失效，不可恢复！',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/courseMember/stopCourseMember',
            data: {
              type: val2,
              member_id: row.member_id,
            },
            callback: () => {
              this.$root.prompt({
                msg: '操作成功',
                type: 'success',
              })
              this.$refs.child.reset()
            },
          })
        })
        .catch(() => {})
    },

    //查看会员
    memberexamine(val) {
      this.$router.push({
        path: '/tool/member/memberexamine',
        query: { member_id: val.member_id },
      })
    },
    //新建
    newmember() {
      this.$router.push({ path: '/tool/member/newmember' })
    },
    //编辑会员
    editMember(val, val2) {
      if (this.showRemoveEdit) {
        this.$router.push({
          path: '/tool/member/newmember',
          query: { type: 2, member_id: val.member_id, iscount: val2 },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dropdown-menu__item {
  width: 90px;
}
.hearder {
  background-color: #fff;
  padding: 20px;
  display: flex;
  .tu,
  .tu2,
  .tu3 {
    width: 131px;
    height: 131px;
    background: #3299ff;
    border-radius: 20px;
    img {
      margin: 16px auto;
    }
  }
  .tu2 {
    background: #ff6c32;
    img {
      margin-top: 26px;
    }
  }
  .tu3 {
    background-color: #30b031;
    img {
      margin-top: 26px;
    }
  }

  .right {
    margin-left: 30px;
    .right_font1 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }
    .right_font2 {
      margin-top: 14px;
      margin-bottom: 50px;
      font-size: 13px;
      color: #808080;
      line-height: 20px;
    }
    .right_button {
      display: flex;
      align-items: flex-end;
      .price {
        font-size: 18px;
        font-weight: bold;
        color: #ff3535;
        margin-left: 24px;
        margin-right: 12px;
      }
      .strick {
        font-size: 13px;
        text-decoration: line-through;
        color: #808080;
        line-height: 13px;
      }
    }
  }
}
::v-deep .dialogVisible {
  .el-dialog__body {
    padding: 0 0 40px;
  }
  .dialogVisible_main {
    width: 280px;
    margin: 0 auto;
    position: relative;

    .qrcode {
      box-sizing: border-box;
      overflow: hidden;
      padding: 5px;
      height: 118px;
      width: 118px;
      background-color: #fff;
      position: absolute;
      top: 49px;
      left: 50%;
      transform: translateX(-50%);
    }
    .font1 {
      height: 92px;
      width: 100%;
      position: absolute;
      top: 187px;
      .font_first {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        line-height: 18px;
      }
      .font_scond {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        margin: 12px 0 25px;
      }
      .font_third {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
      }
    }
    .btns {
      display: flex;
      justify-content: center;
    }

    .result-pic {
      width: 280px;
      height: 314px;
      position: absolute;
      top: 0px;
      left: 0px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
#ordinary {
  .main {
    padding: 20px;
    .header {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    .header_tow {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
      align-items: center;
      .header_tow_font {
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        margin: 0 10px;
      }
      .el-icon-question {
        color: #cccccc;
      }
      .popover_main {
        .font1 {
          font-size: 12px;
          color: #4a4a4a;
          line-height: 18px;
        }
      }
    }
    .el-dropdown-link,
    .el-divider {
      color: #1b9d97;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .name_contain {
      display: flex;
      .tu1 {
        width: 100px;
        height: 56px;
        border-radius: 4px;
      }
      .right {
        margin-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .rigth_font {
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .right_font2 {
          align-items: center;
          display: flex;
          font-size: 12px;
          color: #666666;
          line-height: 12px;
          margin-top: 28px;
          img {
            margin-right: 6px;
            height: 7px;
            width: 7px;
          }
        }
      }
    }
  }
}
</style>
