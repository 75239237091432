<template>
  <div id="membership">
    <div class="hearder" v-if="is_course_member == 2">
      <div class="tu">
        <img src="../../../assets/img/1.4.0/icon_hybs@2x.png" alt="" />
      </div>
      <div class="right">
        <div class="right_font1">普通会员</div>
        <div class="right_font2">
          普通会员是一种按时长计算的内容付费形式，学员购买后能在会员有效期内查看特定课程；一键添加课程，一键设定会员角色，快速添加到网校展示售卖，提高网校的变现和转化能力。
        </div>
        <div class="right_button">
          <el-button
            type="primary"
            size="medium"
            @click="kaitong"
            style="width: 112px"
          >
            立即开通
          </el-button>
          <div class="price">
            ￥599
            <span
              class="red"
              style="font-size: 14px; marign-left: 5px; font-weight: bold"
            >
              /年
            </span>
          </div>
          <div class="strick">￥999</div>
        </div>
      </div>
    </div>
    <div style="background: #fff">
      <!-- <el-tabs v-model="activeName" v-if="$route.path === '/tool/member'">
        <el-tab-pane label="课程会员" name="1"></el-tab-pane>
        <el-tab-pane label="超级会员" name="2"></el-tab-pane>
        <el-tab-pane label="会员卡" name="3"></el-tab-pane>
      </el-tabs> -->
      <!-- <wish v-if="activeName === '2' || activeName === '3'"></wish> -->
      <!-- 课程会员 -->
      <ordinary v-if="activeName === '1' && is_course_member == 1"></ordinary>
      <!-- 超级会员 -->
      <!-- <super-mumber v-if="activeName === '2'"></super-mumber> -->
      <!-- 未开通页面 -->
      <noOpen v-if="is_course_member == 2" :list="list"></noOpen>
    </div>
    <openingfunction
      v-if="status2"
      :dialogstatus.sync="status2"
    ></openingfunction>
  </div>
</template>
<script>
import noOpen from '@/components/copyrightprotect/noOpen' //功能介绍
import bg from '@/mixin/background'
// import superMumber from './components/superMumber'
// import wish from './components/wish'
import ordinary from './components/ordinary'
import openingfunction from '@/components/copyrightprotect/openingfunction'
export default {
  name: 'membership',
  mixins: [bg],
  data() {
    return {
      status2: false, //客服弹窗
      activeName: '1',
      list: {
        function_tu: require('@/assets/img/1.4.0/img_hyjs@2x.png'),
      },
    }
  },
  components: {
    ordinary,
    // wish,
    openingfunction,
    noOpen,
    // superMumber,
  },
  computed: {
    is_course_member() {
      return this.$store.state.userInfo.is_course_member
    },
  },
  methods: {
    kaitong() {
      this.status2 = true
    },
  },
}
</script>
<style lang="scss" scoped>
#membership {
  margin: 20px;
}
::v-deep #noOpen {
  margin: 0;
}
::v-deep .introduction {
  margin-top: 0;
}
.hearder {
  background-color: #fff;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
  .tu,
  .tu2,
  .tu3 {
    width: 131px;
    height: 131px;
    background: #f27623;
    border-radius: 20px;
    img {
      margin: 18px auto 0;
      width: 118px;
      height: 105px;
    }
  }
  .tu2 {
    background: #ff6c32;
    img {
      margin-top: 26px;
    }
  }
  .tu3 {
    background-color: #30b031;
    img {
      margin-top: 26px;
    }
  }

  .right {
    width: calc(100% - 161px);
    margin-left: 30px;
    .right_font1 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }
    .right_font2 {
      height: 40px;
      margin-top: 14px;
      margin-bottom: 21px;
      font-size: 13px;
      color: #808080;
      line-height: 20px;
    }
    .right_button {
      display: flex;
      align-items: flex-end;
      .price {
        font-size: 18px;
        font-weight: bold;
        color: #ff3535;
        margin-left: 24px;
        margin-right: 12px;
      }
      .strick {
        font-size: 13px;
        text-decoration: line-through;
        color: #808080;
        line-height: 13px;
      }
    }
  }
}
::v-deep .el-tabs__nav-wrap:after {
  height: 1px;
}
::v-deep .el-tabs__nav-scroll {
  padding: 0 20px;
}
</style>
